
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "Blog",
  data() {
    return {
      blog_detail: { type: String, default: "" },
      pageWidth: 960,
      deviceType: "",
    };
  },
  beforeCreate() {
    document.title = "学习心得分享";
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    searchPageWidth() {
      let w = window.innerWidth;
      // console.log(w);
      if (this.isMobile()) {
        this.pageWidth = w - 40;
      } else {
        if (w <= 960) {
          this.pageWidth = w - 40;
        } else {
          this.pageWidth = 960;
        }
      }
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    axios
      .get(
        "https://chenggang-jia.com:4450/myBlog/BlogDetail/" +
          this.$route.params.id +
          "/"
      )
      .then((response) => (this.blog_detail = response.data));
    this.searchPageWidth();
  },
});
