
import { defineComponent } from "vue";
import axios from "axios";
import HomeBackground from "./HomeBackground.vue";

export default defineComponent({
  components: {
    HomeBackground,
  },
  data() {
    return {
      info: "",
      pageWidth: 733.44,
    };
  },
  methods: {
    searchPageWidth() {
      let w = window.innerWidth;
      // console.log(w);
      if (this.isMobile()) {
        this.pageWidth = w - 40;
      } else {
        if (w <= 960) {
          this.pageWidth = w - 40;
        } else {
          this.pageWidth = 733.44;
        }
      }
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    axios
      .get("https://chenggang-jia.com:4450/myCV/")
      .then((response) => (this.info = response.data));
    this.searchPageWidth();
  },
});
