import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_backtop = _resolveComponent("el-backtop")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NavBar, { title: '学习心得分享' }),
    _createVNode(_component_router_view),
    _createVNode(_component_el_backtop),
    _createVNode(_component_Footer)
  ]))
}