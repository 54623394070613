import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue'
import HomePage from "../views/HomePage.vue"
import HomeCV from "../views/HomeCV.vue"
import Blog from "../views/Blog.vue"
import Project from "../views/Project.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/CV',
    name: 'HomeCV',
    component: HomeCV
  },
  {
    path: "/Blog/:id",
    name: "Blog",
    component: Blog
  },
  {
    path: '/project',
    name: 'Project',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Project
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
