
import { defineComponent } from "vue";
import axios from "axios";
import { Avatar, Timer, PriceTag } from "@element-plus/icons-vue";

export default defineComponent({
  name: "BlogList",
  components: {
    Avatar,
    Timer,
    PriceTag,
  },
  data() {
    return {
      bloglist: "",
      pageWidth: 733.44,
      deviceType: "",
    };
  },
  methods: {
    searchPageWidth() {
      let w = window.innerWidth;
      // console.log(w);
      if (this.isMobile()) {
        this.pageWidth = w - 40;
      } else {
        if (w <= 960) {
          this.pageWidth = w - 40;
        } else {
          this.pageWidth = 733.44;
        }
      }
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    change_page(page: number) {
      axios
        .get("https://chenggang-jia.com:4450/myBlog/BlogList/" + page + "/")
        .then((response) => (this.bloglist = response.data));
    },
  },
  mounted() {
    this.change_page(1);
    this.searchPageWidth();
  },
});
