
import { defineComponent } from "vue";
// import "element-plus/theme-chalk/display.css";

export default defineComponent({
  name: "NavBar",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
});
