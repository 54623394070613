
import { defineComponent } from "vue";
export default defineComponent({
  name: "HomeBackground",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  beforeCreate() {
    document.title = "学习心得分享";
  },
});
