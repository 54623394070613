import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17efac16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative","margin-bottom":"1vh"} }
const _hoisted_2 = {
  class: "welcome-style",
  style: {"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_image, {
      src: "https://chenggang-jia.com:4450/static/media/img/2022-02-06/20201021014203008f7a0dedc456ff63039d9c036de4ae.jpg",
      fit: "fill",
      style: {"height":"25vh","width":"100%"}
    }),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1)
  ]))
}