import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeBackground = _resolveComponent("HomeBackground")!
  const _component_BlogList = _resolveComponent("BlogList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HomeBackground, { title: 'Welcome to My Website ^_^' }),
    _createVNode(_component_BlogList)
  ]))
}