
import { defineComponent } from "vue";
import HomeBackground from "./HomeBackground.vue";
import BlogList from "./BlogList.vue";

export default defineComponent({
  name: "HomeMain",
  components: {
    HomeBackground,
    BlogList,
  },
});
