import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b36ed1bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-type" }
const _hoisted_2 = { class: "hidden-xs-only" }
const _hoisted_3 = { class: "nav-menu-type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_space = _resolveComponent("el-space")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_space, { wrap: "" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_space, { wrap: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_avatar, {
                  size: 'default',
                  src: "https://chenggang-jia.com:4450/static/media/img/2022-02-05/logo.png"
                }),
                _createVNode(_component_router_link, {
                  to: '/',
                  class: "font-type"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_space, {
              wrap: "",
              size: 20
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_router_link, {
                    to: "/",
                    class: "nav-menu-font-type nav-menu-type"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 主页 ")
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_router_link, {
                    to: "/project",
                    class: "nav-menu-font-type"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 研究项目 ")
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_router_link, {
                    to: "/CV",
                    class: "nav-menu-font-type"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 关于我 ")
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ])
  ]))
}